import React, { useEffect, useRef, useState, useCallback } from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import { useImmer } from "use-immer";

export default function Matrix({ className, ...props }) {
	const [state, updateState] = useImmer({
		loaded: false,
		width: 1,
		height: 1,
		wrapperElement: null,
	});

	const canvasRef = useRef(null);

	const [config, setConfig] = useState(isObject(props.config) ? props.config : {});
	const configRef = useRef(config);
	useEffect(() => {
		if (isObject(props.config) && !isEqual(configRef.current, props.config)) {
			setConfig((configRef.current = props.config));
		}
	}, [props.config]);

	useEffect(() => {
		let mounted = true;
		updateState((draft) => void (draft.loaded = false));

		if (canvasRef.current) {
			let dispose = null;
			(async () => {
				let main = null;
				try {
					main = await import("@/matrix/js/main");
				} catch (error) {
					console.error(error);
				}
				if (main && main.loadMatrix) {
					try {
						dispose = await main.loadMatrix(canvasRef.current, config);
					} catch (error) {
						console.error(error);
					}
					if (mounted) {
						updateState((draft) => void (draft.loaded = true));
					} else {
						if (dispose) {
							dispose();
							dispose = null;
						}
					}
				}
			})();

			return () => {
				mounted = false;
				if (dispose) {
					dispose();
					dispose = null;
				}
			};
		}
	}, [config, updateState]);

	const setWrapperElement = useCallback(
		(element) => updateState((draft) => void (draft.wrapperElement = element)),
		[updateState]
	);

	useEffect(() => {
		let mounted = true;
		if (state.wrapperElement) {
			const resizeObserver = new ResizeObserver((entries) => {
				entries.forEach((entry) => {
					if (state.wrapperElement && entry.target === state.wrapperElement) {
						if (mounted) {
							updateState((draft) => {
								draft.width = entry.contentRect.width;
								draft.height = entry.contentRect.height;
							});
						}
					}
				});
			});

			const boundingClientRect = state.wrapperElement.getBoundingClientRect();
			updateState((draft) => {
				draft.width = boundingClientRect.width;
				draft.height = boundingClientRect.height;
			});

			resizeObserver.observe(state.wrapperElement);

			return () => {
				mounted = false;
				resizeObserver.disconnect();
			};
		}
	}, [state.wrapperElement, updateState]);

	useEffect(() => {
		if (canvasRef.current) {
			canvasRef.current.width = state.width;
		}
	}, [state.width]);

	useEffect(() => {
		if (canvasRef.current) {
			canvasRef.current.height = state.height;
		}
	}, [state.height]);

	return (
		<div ref={setWrapperElement} className={tailwindCascade("relative w-full h-full", className)}>
			<canvas
				ref={canvasRef}
				className={tailwindCascade("absolute left-0 top-0 w-full h-full transition-opacity", {
					"opacity-0": !state.loaded,
					"opacity-100": state.loaded,
				})}
			/>
		</div>
	);
}
