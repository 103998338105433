import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import IconDropdown from "@/images/icons/icon-dropdown.svg";

export default forwardRef(function FlatSelector({ value, onChange, disabled, className, children }, ref) {
	const [state, setState] = useState({
		value: `${value ?? ""}`, // Should be string
		text: undefined,
	});
	const stateRef = useRef(state);
	useEffect(() => void (stateRef.current = state), [state]);

	useImperativeHandle(
		ref,
		() => {
			return {
				get value() {
					if (stateRef.current) {
						return stateRef.current.value;
					}
					return undefined;
				},
				get text() {
					if (stateRef.current) {
						return stateRef.current.text;
					}
					return undefined;
				},
			};
		},
		[]
	);

	const selectRef = useRef(null);
	const onChangeSelect = useCallback(() => {
		if (selectRef.current) {
			const option = selectRef.current.options[selectRef.current.selectedIndex];
			if (option) {
				const text = option.text;
				const value = option.value;
				return setState({
					value,
					text,
				});
			}
		}
		setState({
			value: "",
			text: undefined,
		});
	}, []);
	useEffect(() => onChangeSelect, [onChangeSelect]);

	useEffect(() => {
		const stringValue = `${value ?? ""}`; // Should be string
		let selectedIndex = 0;
		for (let i = 0; i < selectRef.current.options.length; i++) {
			if (selectRef.current.options[i].value === stringValue) {
				selectedIndex = i;
				break;
			}
		}
		selectRef.current.selectedIndex = selectedIndex;
		onChangeSelect();
	}, [onChangeSelect, value]);

	useEffect(() => {
		if (onChange) {
			onChange(state.value);
		}
	}, [onChange, state.value]);

	return (
		<div className={tailwindCascade("text-base font-bold leading-none text-white", className)}>
			<label className="block group relative">
				<select
					ref={selectRef}
					value={state.value}
					className="absolute top-0 left-0 w-full h-full p-0 m-0 border-0 opacity-0 appearance-none cursor-pointer"
					onChange={onChangeSelect}
					disabled={disabled}
				>
					{children}
				</select>
				<div className="flex flex-row items-center justify-center gap-1 pointer-events-none select-none">
					<div className="group-hover:underline whitespace-nowrap">{state.text}</div>
					<IconDropdown className="w-4 h-4" />
				</div>
			</label>
		</div>
	);
});
