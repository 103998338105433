import React, { useState, useRef, useCallback, useEffect, memo } from "react";

import animationLoop from "@/helpers/animationLoop";
import random from "lodash/random";
import isEqual from "lodash/isEqual";
import useRefMounted from "@/hooks/useRefMounted";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import QImage from "@/images/background/duck.svg";
import UImage from "@/images/background/heart.svg";
import IImage from "@/images/background/icecream.svg";
import ZImage from "@/images/background/star-1.svg";
import CImage from "@/images/background/star-2.svg";
import AImage from "@/images/background/star-3.svg";
import TImage from "@/images/background/banana.svg";
import HandLeftImage from "@/images/background/cupcake.svg";
import HandRightImage from "@/images/background/puzzle.svg";
import HeadImage from "@/images/background/candy.svg";

import Check1 from "@/images/background/check1.svg";
import Check2 from "@/images/background/check2.svg";
import Check3 from "@/images/background/check3.svg";
import Check4 from "@/images/background/check4.svg";
import Check5 from "@/images/background/check5.svg";
import Check6 from "@/images/background/check6.svg";
import Check7 from "@/images/background/check7.svg";
import Check8 from "@/images/background/check8.svg";
import Check9 from "@/images/background/check9.svg";
import Check10 from "@/images/background/check10.svg";

import {
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import Matrix from "@/matrix/Matrix";
import { PLAY_STATUS_LOBBY } from "@/constants";
import { BLACK, HEX_TO_NORMALIZED_RGB_STRING, PETROL_DARK, PETROL_DARKER, PETROL_DARKEST } from "@/colors";

const IMAGE_SIZE = 1 / 8;
const SUPPORTS_MIN = typeof window !== "undefined" && window.CSS && window.CSS.supports("width: min(1vw, 1vh)");
const BACKGROUND_TRANSITION_DURATION = 0.5;

function randomizeStartPosition(initial = false) {
	return {
		x: random(-IMAGE_SIZE / 2, 1 + IMAGE_SIZE / 2),
		y: initial ? random(-1, -IMAGE_SIZE) : random(-IMAGE_SIZE * 4, -IMAGE_SIZE * 2),
		r: random(0, Math.PI * 2),
		xp: random(),
		xs: random(Math.PI / 1, Math.PI),
		xm: random(0.01 * 0.5, 0.02 * 0.5),
		ym: random(0.125 * 0.5, 0.33 * 0.5),
		rm: random(0.1, 0.2) * (random(0, 1, false) ? -1 : 1),
	};
}

function BackgroundAnimation(props) {
	const [backgroundAnimationEnabled, setBackgroundAnimationEnabled] = useState(props.backgroundAnimationEnabled);
	const [quiz, setQuiz] = useState(props.quiz);
	const [slideIndex, setSlideIndex] = useState(props.slideIndex);
	const [statusName, setStatusName] = useState(props.statusName);
	const [aiMode, setAiMode] = useState(props.aiMode);
	const [reducedMotion, setReducedMotion] = useState(props.reducedMotion);

	useEffect(
		() => void setBackgroundAnimationEnabled(props.backgroundAnimationEnabled),
		[props.backgroundAnimationEnabled]
	);
	useEffect(() => void setQuiz(props.quiz), [props.quiz]);
	useEffect(() => void setSlideIndex(props.slideIndex), [props.slideIndex]);
	useEffect(() => void setStatusName(props.statusName), [props.statusName]);
	useEffect(() => void setAiMode(props.aiMode), [props.aiMode]);
	useEffect(() => void setReducedMotion(props.reducedMotion), [props.reducedMotion]);

	const [mounted] = useRefMounted();

	const qRef = useRef(null);
	const uRef = useRef(null);
	const iRef = useRef(null);
	const zRef = useRef(null);
	const cRef = useRef(null);
	const aRef = useRef(null);
	const tRef = useRef(null);
	const handLeftRef = useRef(null);
	const handRightRef = useRef(null);
	const headRef = useRef(null);

	const qPositionRef = useRef(randomizeStartPosition(true));
	const uPositionRef = useRef(randomizeStartPosition(true));
	const iPositionRef = useRef(randomizeStartPosition(true));
	const zPositionRef = useRef(randomizeStartPosition(true));
	const cPositionRef = useRef(randomizeStartPosition(true));
	const aPositionRef = useRef(randomizeStartPosition(true));
	const tPositionRef = useRef(randomizeStartPosition(true));
	const handLeftPositionRef = useRef(randomizeStartPosition(true));
	const handRightPositionRef = useRef(randomizeStartPosition(true));
	const headPositionRef = useRef(randomizeStartPosition(true));

	const refs = useRef([
		[qRef, qPositionRef],
		[uRef, uPositionRef],
		[iRef, iPositionRef],
		[zRef, zPositionRef],
		[cRef, cPositionRef],
		[aRef, aPositionRef],
		[tRef, tPositionRef],
		[handLeftRef, handLeftPositionRef],
		[handRightRef, handRightPositionRef],
		[headRef, headPositionRef],
	]);

	const [isCheckBoxes, setIsCheckBoxes] = useState(false);

	useEffect(() => {
		const currentSlide = quiz && quiz.slides[slideIndex];
		setIsCheckBoxes(currentSlide && currentSlide.type === SLIDE_TYPE_CHECK_BOXES);
	}, [quiz, slideIndex]);

	useEffect(() => {
		if (!reducedMotion) {
			const dispose = animationLoop((delta) => {
				if (refs.current) {
					for (let i = 0; i < refs.current.length; i++) {
						const ref = refs.current[i][0];
						if (ref.current) {
							const position = refs.current[i][1];
							const style = ref.current.style;
							const x = position.current.x + Math.sin(position.current.xp) * position.current.xm;
							style.transform = `translate3d(${x * 100}vw, ${position.current.y * 100}vh, 0) rotate(${
								position.current.r
							}rad)`;
							style.display = "block";

							position.current.xp += delta * position.current.xs;
							position.current.r += delta * position.current.rm;

							let y = position.current.y + delta * position.current.ym;
							if (y > 1 + IMAGE_SIZE) {
								position.current = randomizeStartPosition();
							} else {
								position.current.y = y;
							}
						}
					}
				}
			});
			return () => void dispose();
		}
	}, [backgroundAnimationEnabled, reducedMotion]);

	const getImage = useCallback(
		(index) => {
			const ref = [qRef, uRef, iRef, zRef, cRef, aRef, tRef, handLeftRef, handRightRef, headRef][index];
			const CheckboxImage = [Check1, Check2, Check3, Check4, Check5, Check6, Check7, Check8, Check9, Check10][
				index
			];
			const Image = [
				QImage,
				UImage,
				IImage,
				ZImage,
				CImage,
				AImage,
				TImage,
				HandLeftImage,
				HandRightImage,
				HeadImage,
			][index];

			return (
				<div
					ref={ref}
					className="absolute top-0 left-0 hidden origin-center"
					style={{
						width: SUPPORTS_MIN
							? `min(${IMAGE_SIZE * 100}vw, ${IMAGE_SIZE * 100}vh)`
							: `${IMAGE_SIZE * 100}vw`,
						height: SUPPORTS_MIN
							? `min(${IMAGE_SIZE * 100}vw, ${IMAGE_SIZE * 100}vh)`
							: `${IMAGE_SIZE * 100}vw`,
					}}
				>
					<Image
						className={tailwindCascade(
							"absolute",
							"object-contain",
							"w-full",
							"h-full",
							"text-background-dark",
							{
								hidden: isCheckBoxes,
								block: !isCheckBoxes,
							}
						)}
					/>
					<CheckboxImage
						className={tailwindCascade(
							"absolute",
							"object-contain",
							"w-full",
							"h-full",
							"text-background-light",
							{
								block: isCheckBoxes,
								hidden: !isCheckBoxes,
							}
						)}
					/>
				</div>
			);
		},
		[isCheckBoxes]
	);

	return (
		mounted &&
		(aiMode ? (
			<div
				className={tailwindCascade("-z-10 absolute inset-0 transition-opacity duration-300 bg-petrol-darkest", {
					"opacity-100": statusName !== PLAY_STATUS_LOBBY,
				})}
			>
				{!reducedMotion ? (
					<Matrix
						className="w-full h-full"
						config={{
							skipIntro: true,
							backgroundColor: HEX_TO_NORMALIZED_RGB_STRING(PETROL_DARKEST),
						}}
					/>
				) : (
					<img
						src="/images/backgrounds/reduced-motion-matrix.png"
						alt=""
						className="object-cover object-center w-full h-full"
					/>
				)}
			</div>
		) : (
			<div
				className={tailwindCascade("-z-10 fixed inset-0 overflow-hidden duration-500 transition-colors", {
					block: backgroundAnimationEnabled,
					hidden: !backgroundAnimationEnabled,
				})}
			>
				{!reducedMotion ? (
					<>
						{getImage(0)}
						{getImage(1)}
						{getImage(2)}
						{getImage(3)}
						{getImage(4)}
						{getImage(5)}
						{getImage(6)}
						{getImage(7)}
						{getImage(8)}
						{getImage(9)}
					</>
				) : (
					<img
						src="/images/backgrounds/reduced-motion.png"
						alt=""
						className="object-cover object-center w-full h-full"
					/>
				)}
			</div>
		))
	);
}

const MemorizedBackgroundAnimation = memo(BackgroundAnimation, isEqual);
export default MemorizedBackgroundAnimation;
